<template>
  <div class="container-right">
    <div class="container-right-name">
        帮助中心
        <div class="line"></div>
    </div>
    <div class="collapse">
      <h3>账号</h3>
      <el-collapse>
        <el-collapse-item title="使用知链需要实名存证吗?" name="1">
          <div class="p">
            对于创作用户，需要通过实名存证才可以使用知链版权存证等系列服务。目的是使版权归属严谨有理，确保版权资产的安全。
<br>对于转载用户，获取授权不需要实名存证。
<br>实名需提供有效身份证号等，实名存证完成后用户头像处显示“已实名”标签。
<br>在中华人民共和国境内，凡是中国公民，法人或者非法人单位的作品，不论是否发表都享有著作权；外国人的作品首先在中国境内发表的，也依著作权法享有著作权；外国人在中国境外发表的作品，根据其所属国与中国签订的协议或者共同参加的国际条约享有著作权。
          </div>
        </el-collapse-item>
        <el-collapse-item title="如何修改账号密码?" name="2">
          <div class="p">
            在个人信息的账号处点击“修改密码”，可重新设置登录密码。
          </div>
        </el-collapse-item>
        <el-collapse-item title="为什么用户名（笔名）只能修改一次？" name="3">
          <div class="p">
           知链提供一次修改用户笔名的机会。知链原创存证DNA标签中包含作者的署名信息，保持稳定的用户名（笔名）能更好证明原创作品的归属，因此知链不建议频繁的更换笔名。
          </div>
        </el-collapse-item>
        <el-collapse-item title="知链账号绑定个人微信号后有什么作用?" name="4">
          <div class="p">
            1.用于提现，当原创作品获得交易收益后，可以直接提现到微信钱包。<br>
2.用于接收消息，实时接收转载和交易提醒。
          </div>
        </el-collapse-item>
      </el-collapse>
      <h3>知链版权存证</h3>
      <el-collapse>
        <el-collapse-item title="什么样的内容可以提交存证？" name="5">
          <div class="p">
            知链目前支持文本内容存证与图片内容存证。<br>
文本内容指一切具有独创性并能以文本形式复制的智力成果。包括诗歌、文章、小说、论文、剧本等等。<br>
图片内容指一切具有独创性并能以图片形式复制的智力成果。包括摄影、绘画、设计、手稿等等。
          </div>
        </el-collapse-item>
        <el-collapse-item title="作品存证成功后发给我的邮件有什么作用?" name="6">
          <div class="p">
            存证邮件中包含了生成作品数字签名时的密钥。请务必保管好存证邮件，并不要让他人知晓邮件中的密钥。当出现版权纠纷时，该邮件中的密钥及相关存证信息将能够作为作品所有权的证明，帮助用户维护版权利益。
          </div>
        </el-collapse-item>
        <el-collapse-item title="存证后的内容在哪里查看和管理？" name="7">
          <div class="p">
            存证后的内容可在“我的存证”中进行查看和管理。
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
 components:{},
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
@import '../../assets/css/mine.scss';

</style>
